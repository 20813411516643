<template>
    <div class="login-page">
        <PageHeader title="登录" />
        <div class="login-wrapper">
            <div class="form">
                <div class="form-item">
                    <input
                        class="phone"
                        type="number"
                        maxlength="11"
                        placeholder="请输入手机号"
                        v-model="phone"
                    />
                    <img v-if="phone" class="close" src="./img/close.png" @click="clear" />
                </div>
                <div class="form-item">
                    <input
                        class="code"
                        type="number"
                        maxlength="6"
                        placeholder="请输入验证码"
                        v-model="code"
                    />
                    <span class="get-code" v-if="this.phone.length == 11" @click="getCode">
                        {{ codeText }}
                    </span>
                </div>
            </div>
            <button class="btn" @click="login">立即登录</button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import PageHeader from '@/components/header';
import { post } from '@/modules/request.js';
import loadScript from '@/modules/load-script.js';
import config from '@/modules/config.js';
import { cookie } from '@kk/utils';
export default {
    name: 'Login',
    components: { PageHeader },
    data() {
        return {
            phone: '',
            code: '',
            remain: 0,
            from: null,
            logining: false,
        };
    },
    computed: {
        codeText() {
            if (this.remain > 0) {
                return `${this.remain}s后重新获取`;
            }
            return '获取验证码';
        },
        ...mapState(['userInfo']),
    },
    beforeDestroy() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    },
    created() {
        document.title = '登录';
        const { from, back } = this.$route.query;
        this.from = from;
        this.back = back;
        this.loadTencentCaptcha();
    },
    async mounted() {
        if (cookie.get('passToken') && this.userInfo.isLogin) {
            this.$router.replace({ path: this.from || '/index' });
        } else {
            loading.hide();
        }
    },
    methods: {
        // 加载滑块验证所需代码
        async loadTencentCaptcha() {
            if (!window.TencentCaptcha) {
                await loadScript('https://turing.captcha.qcloud.com/TCaptcha.js');
            }
        },
        // 显示滑块验证： https://cloud.tencent.com/document/product/1110/36841
        async showTencentCaptcha() {
            return new Promise((resolve, reject) => {
                try {
                    this.loadTencentCaptcha().then(() => {
                        const captcha = new TencentCaptcha('2002112217', (res) => {
                            if (res.ret === 0) {
                                resolve(res);
                            } else {
                                reject(res.ret);
                            }
                        });
                        captcha.show();
                    });
                } catch (error) {
                    this.$toast.show('滑块验证失败');
                    reject(error);
                }
            });
        },
        clear() {
            this.phone = '';
        },
        startTimer() {
            this.timer = setTimeout(() => {
                this.remain -= 1;
                this.timer = 0;
                this.remain > 0 && this.startTimer();
            }, 1000);
        },
        async getCode() {
            if (this.remain) {
                return;
            }
            if (this.phone.length !== 11) {
                this.$toast.show('请填写正确的手机号');
                return;
            }
            let captchaResult = {};
            try {
                captchaResult = await this.showTencentCaptcha();
            } catch (error) {
                console.error(error);
                return;
            }
            const { ticket, randstr: random } = captchaResult;
            post({
                url: `${process.env.VUE_APP_API}/v2/passport/m_distribute_sale/send_code`,
                data: {
                    phone: this.phone,
                    ticket,
                    random,
                },
            }).then(
                (res) => {
                    if (res.code !== 200) {
                        this.$toast.show(res.message);
                    } else {
                        this.remain = 60;
                        this.startTimer();
                        this.$toast.show('验证码发送成功');
                    }
                },
                () => {
                    this.$toast.show('发送验证码失败，请稍后再试～');
                }
            );
        },
        login() {
            if (process.env.NODE_ENV === 'development') {
                this.loginSuccessRedirect();
                return;
            }
            if (this.logining) return;
            if (this.phone.length !== 11) {
                this.$toast.show('请填写正确的手机号');
                return;
            }
            if (!this.code) {
                this.$toast.show('请输入验证码');
                return;
            }
            this.logining = true;
            post({
                url: `${process.env.VUE_APP_API}/v2/passport/m_distribute_sale/phone_signup`,
                data: {
                    phone: this.phone,
                    code: this.code,
                },
            })
                .then(
                    async () => {
                        await this.loginSuccessRedirect();
                    },
                    (e) => {
                        this.$toast.show(e?.message || '登录失败，请稍后再试～');
                    }
                )
                .finally(() => {
                    this.logining = false;
                });
        },
        async loginSuccessRedirect() {
            if (process.env.NODE_ENV === 'development') {
                // 本地开发，需要到测试环境拷贝cookie
                document.cookie = `passToken=${config.DEV_PASSTOKEN}`;
            }
            await this.$store.dispatch('bindUser');
            await this.$store.dispatch('checkLogin');
            if (this.from) {
                this.$router.replace({ path: this.from });
            } else {
                this.$router.go(-1);
            }
        },
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';
.login-page {
    height: 100vh;
}
.login-wrapper {
    .vws(padding-top, 192px);
    .vws(padding-left, 110px);
    .vws(padding-right, 110px);
}
.form {
    .form-item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ccc;
    }
    input {
        padding-right: 14px;
        font-size: 13px;
        border: none;
        color: #333;
        flex: 1;
        box-sizing: border-box;
        line-height: 14px;
        .vws(padding-top, 32px);
        .vws(padding-bottom, 32px);
        &::input-placeholder {
            color: #ccc;
        }
        &:focus {
            border: none;
            outline: none;
        }
    }
    .close {
        width: 16px;
        height: 16px;
    }
    .get-code {
        flex: none;
        font-size: 13px;
        line-height: 32px;
        text-align: right;
        color: #333;
    }
}
.btn {
    .vws(width, 530px);
    .vws(height, 80px);
    .vws(margin-top, 120px);

    font-size: 16px;
    font-weight: 500;
    border-radius: 80px;
    text-align: center;
    color: #fff;
    background-color: #f44;
    line-height: 20px;
}
</style>
