// 动态加载外部的js
export default function loadScript(src) {
    return new Promise((resolve, reject) => {
        const oHead = document.getElementsByTagName('HEAD').item(0);
        const oScript = document.createElement('script');
        oScript.type = 'text/javascript';
        oScript.src = src;
        oScript.onload = function () {
            resolve();
        };
        oScript.onerror = function () {
            reject(`动态加载js: ${src}失败`);
        };
        oHead.appendChild(oScript);
    });
}
